import React from 'react';
import { Icon } from './Icon';

export const IconSteem = props => (
  <Icon {...props}>
    <svg viewBox="0 0 1214.9 1290.8">
      <path d="M146.5 145.3C90.5 192.2 32.7 250 9.2 320.5c-27.1 83.1 10.8 168 47 242C92.3 638.3 132 712.4 166.3 790c27.1 61.4 65 133.6 56 202.3-7.2 48.8-23.5 95.7-32.5 144.5-7.2 32.5 23.5 0 34.3-9 63.2-54.2 131.8-115.6 157.1-198.7 12.6-41.5 5.4-79.5-9-119.2-14.4-37.9-30.7-74-47-112-34.3-75.9-70.4-151.7-106.6-227.6-14.4-27.1-28.9-56-41.5-84.9-12.6-30.7-19.9-63.2-21.7-97.5-1.8-27.1-1.8-52.4 1.8-79.5 1.8-19.9 7.2-37.9 9-57.8 1.9-10.7-7.1-14.4-19.7-5.3zm827.1 0c-56 47-113.8 104.8-137.3 175.2-27.1 83.1 10.8 168 47 242 36.1 75.9 75.9 149.9 110.2 227.6 27.1 61.4 65 133.6 56 202.3-7.2 48.8-23.5 95.7-32.5 144.5-7.2 32.5 23.5 0 34.3-9 63.2-54.2 131.8-115.6 157.1-198.7 12.6-41.5 5.4-79.5-9-119.2-14.4-37.9-30.7-74-47-112-34.3-75.9-70.4-151.7-106.6-227.6-14.4-27.1-28.9-56-41.5-84.9-12.6-30.7-19.9-63.2-21.7-97.5-1.8-27.1-1.8-52.4 1.8-79.5 1.8-19.9 7.2-37.9 9-57.8 1.9-10.8-7.1-14.5-19.8-5.4z"/>
      <path d="M547.4 8c-63.2 52.4-131.8 115.6-169.8 189.6-27.1 52.4-30.7 106.6-16.3 160.7 18.1 65 43.3 130 70.4 193.2 52.4 121 121 234.8 171.6 357.6 18.1 43.3 37.9 92.1 41.5 140.9 3.6 59.6-16.3 122.8-32.5 178.8-3.6 16.3-14.4 37.9-12.6 54.2 1.8 12.6 9 7.2 16.3 1.8 30.7-23.5 61.4-50.6 88.5-77.7 74-70.4 158.9-164.4 151.7-274.5-3.6-45.2-25.3-88.5-43.3-130C793 753.9 771.4 707 749.7 660c-39.7-88.5-81.3-175.2-124.6-261.9-14.4-30.7-32.5-59.6-43.3-90.3-16.3-41.5-23.5-84.9-25.3-128.2-1.8-32.5 0-66.8 3.6-99.3 1.8-21.7 9-41.5 9-63.2 5.4-19.9-5.4-21.7-21.7-9.1z"/>
    </svg>
  </Icon>
);
