import styled from 'styled-components';

const itemsSpacingY = 6;
const itemsSpacingX = 6;

export const ButtonsWrapper = styled.div`
  margin: -${itemsSpacingY}px -${itemsSpacingX}px;
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: ${itemsSpacingY}px ${itemsSpacingX}px;
  }
`;
