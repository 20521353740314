import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';
import {
  addSectionPaddingBottom,
  addSectionPaddingTop,
  media,
} from '../../styles/style-mixins';

export const ScrollWrapper = styled.div`
  background-image: url('/images/bg-gradient-bottom.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const ContentGrid = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${media.xsmall`
    padding-left: calc(35% + ${styleConstants.baseModule * 4}px);
  `}
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ${addSectionPaddingBottom};
  ${addSectionPaddingTop};
`;

export const ImageWrapper = styled.img`
  margin: auto;
  width: 180px;
  position: absolute;
  left: 40%;
  bottom: 0;
  transform: translateX(-100%);

  ${media.xsmall`
    height: 100%;
    width: auto;
    left: 35%;
  `}

  ${media.medium`
    height: 100%;
    width: auto;
    left: 30%;
  `}
`;

export const CompensateImage = styled.div`
  padding-left: 40vw;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: calc(${styleConstants.components.section.mq.paddingTop} - 40px);

  ${media.xsmall`
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    min-height: 0px;
  `};
`;

export const CopyrightWrapper = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  padding: ${styleConstants.baseModule * 2}px 0;
  font-size: ${styleConstants.fontSize.small}px;
  display: grid;
  grid-gap: ${styleConstants.baseModule * 2}px;
`;
