import React from 'react';
import { Icon } from './Icon';

export const IconReddit = props => (
  <Icon {...props}>
    <svg viewBox="0 0 14 14">
      <path d="M14,6.87a1.54,1.54,0,0,0-1.55-1.54,1.56,1.56,0,0,0-1.07.43A8.1,8.1,0,0,0,7.31,4.57l.86-2.72,2.35.54v0a1.27,1.27,0,1,0,1.26-1.26A1.28,1.28,0,0,0,10.6,2L8.08,1.38a.22.22,0,0,0-.26.14l-1,3A8.12,8.12,0,0,0,2.6,5.74a1.56,1.56,0,0,0-1-.41A1.54,1.54,0,0,0,.77,8.2a2.73,2.73,0,0,0-.05.5C.72,11,3.52,12.83,7,12.83S13.22,11,13.22,8.7a2.59,2.59,0,0,0,0-.48A1.52,1.52,0,0,0,14,6.87ZM4,7.93a.93.93,0,1,1,.93.92A.93.93,0,0,1,4,7.93Zm5.29,2.72A3,3,0,0,1,7,11.34H7a3,3,0,0,1-2.23-.69.2.2,0,0,1,0-.3.21.21,0,0,1,.31,0A2.64,2.64,0,0,0,7,10.91H7a2.64,2.64,0,0,0,1.92-.56.21.21,0,0,1,.31,0A.22.22,0,0,1,9.24,10.65Zm-.11-1.8a.92.92,0,1,1,.92-.92A.92.92,0,0,1,9.13,8.85Z" />
    </svg>
  </Icon>
);
