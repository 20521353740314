import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const RouteHeading2 = styled.h4`
  font-size: 20px;
  line-height: 1.13353;
  font-weight: 600;
  margin: 1.5em 0 1.2em;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 30px;
    line-height: 1.1;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    font-size: 38px;
    line-height: 1.08365;
  }
`;
