import React from 'react';
import { Icon } from './Icon';

export const IconNabla = props => (
  <Icon {...props}>
    <svg viewBox="0 0 30 30" x="0px" y="0px">
      <path d="M 28.046 0 L 2.638 0 C 1.075 0 -0.098 1.35 0 2.797 L 12.508 28.553 C 13.485 30.482 16.319 30.482 17.296 28.553 L 29.805 2.797 C 30.391 1.543 29.511 0 28.046 0 Z M 25.798 4.63 L 17.296 21.994 C 16.808 22.958 15.537 22.958 15.049 21.994 L 5.863 2.797 L 24.723 2.797 C 25.603 2.797 26.189 3.762 25.798 4.63 Z" />
    </svg>
  </Icon>
);
