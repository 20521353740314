import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 87.5%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    max-width: 692px;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    max-width: 980px;
  }
`;
