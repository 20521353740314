import styled from 'styled-components';

export const HiddenBlock = styled.span`
  opacity: 0;
  position: fixed;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  display: none;
`;
