import React from 'react';
import { Icon } from './Icon';

export const IconArrowExpand = props => (
    <Icon {...props}>
        <svg viewBox="0 0 24 24">
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
        </svg>
    </Icon>
);
