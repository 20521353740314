import React from 'react';
import { Wrapper } from './styles';

export const Logo = ({ fullColor, link }) => (
  <Wrapper as={link ? 'a' : 'div'} href={link}>
    <img
      src={
        fullColor
          ? '/images/logo-accent-full.svg'
          : '/images/logo-mono-full.svg'
      }
      alt="nabla"
    />
  </Wrapper>
);
