import React from 'react';
import { AnimReplacingBlock } from '../../components';
import styleConstants from '../../styles/style-constants';

const platformsList = [
  <span style={{ color: styleConstants.paletteBrand.twitter }}>Twitter</span>,
  <span style={{ color: styleConstants.paletteBrand.reddit }}>Reddit</span>,
  <span style={{ color: styleConstants.paletteBrand.steem }}>Steem</span>,
];

export class PlatformsBlock extends React.Component {
  state = {
    startAnimation: false,
  };

  componentDidMount() {
    this.setState({
      startAnimation: true,
    });
  }

  render() {
    return (
      <AnimReplacingBlock
        startAnimation={this.state.startAnimation}
        items={platformsList}
      />
    );
  }
}
