import React from 'react';
import {
  Header as HeaderBase,
  Logo,
  Menu,
  MenuGroup,
  MenuItem,
} from '../components';

export const Header = ({ logoFullColor, logoHref }) => (
  <HeaderBase>
    <Logo fullColor={logoFullColor} link={logoHref} />

    <Menu>
      <MenuGroup
        style={{
          justifyContent: 'flex-end',
          marginRight: '-0.5em',
          padding: 0,
        }}
      >
        <MenuItem to="/help">help</MenuItem>
      </MenuGroup>
    </Menu>
  </HeaderBase>
);
