import styled from 'styled-components';
import colorMap from './constants';

const iconSize = '1.75em';

const colorScheme = ({ outlined, colorScheme = 'primary' }) => outlined 
  ? `
    color: ${colorMap[colorScheme].borderColor};
  `
  : 'color: currentColor';

export const ButtonIcon = styled.div`  
  width: ${iconSize};
  height: ${iconSize};

  ${colorScheme}
`;
