import React from 'react';
import {
  ScrollWelcome,
  ScrollPlatforms,
  ScrollFlowDemoSend,
  ScrollDigitalCash,
  ScrollControlYourMoney,
} from '../containers/landing';

function Landing() {
  return (
    <>
      <ScrollWelcome />
      <ScrollPlatforms />
      <ScrollFlowDemoSend />
      <ScrollDigitalCash />
      <ScrollControlYourMoney />
    </>
  );
}

export default Landing;
