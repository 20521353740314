import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const Headline = styled.h2`
  font-size: 40px;
  line-height: 1.0625;
  font-weight: 600;
  margin: 0;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 64px;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    font-size: 80px;
  }
`;

export const HeadlineBox = styled.div`
  margin-bottom: 40px;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    margin-bottom: 64px;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    margin-bottom: 80px;
  }
`;
