import Color from 'color';
import constants from '../../styles/style-constants';

export default {
  white: {
    bgColor: Color(constants.color.primary)
      .lighten(0.63)
      .string(),
    bgColorHover: Color(constants.color.primary)
      .lighten(0.58)
      .string(),
    color: constants.color.primary,
  },

  primary: {
    bgColor: constants.color.primary,
    bgColorHover: Color(constants.color.primary)
      .darken(0.15)
      .string(),
    color: constants.textColor.bodyAlt,
  },

  secondary: {
    bgColor: constants.color.secondary,
    bgColorHover: Color(constants.color.secondary)
      .darken(0.15)
      .string(),
    color: '#fff',
  },

  dim: {
    bgColor: constants.color.grey,
    bgColorHover: Color(constants.color.grey)
      .darken(0.15)
      .string(),
    color: '#fff',
  },

  success: {
    bgColor: constants.color.green,
    bgColorHover: Color(constants.color.green)
      .darken(0.15)
      .string(),
    color: '#fff',
  },

  flat: {
    bgColor: Color(constants.paletteBlueGrey[900])
      .alpha(0)
      .string(),
    bgColorHover: 'rgba(0,0,0,0.1)',
    color: constants.paletteBlueGrey[900],
  },

  twitter: {
    bgColor: constants.paletteBrand.twitter,
    bgColorHover: Color(constants.paletteBrand.twitter)
      .darken(0.15)
      .string(),
    color: '#fff',
  },

  discord: {
    bgColor: constants.paletteBrand.discord,
    bgColorHover: Color(constants.paletteBrand.discord)
      .darken(0.15)
      .string(),
    color: '#fff',
  },

  steem: {
    bgColor: constants.paletteBrand.steem,
    bgColorHover: Color(constants.paletteBrand.steem)
      .darken(0.15)
      .string(),
    color: '#fff',
  },
};
