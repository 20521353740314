import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { config } from './config';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalStyles, ResetStyles } from './styles';
import { App as AppWrapper, HiddenBlock } from './components';
import { CookieBanner, ScrollToTop } from './containers';
import { Help, Landing, MobileAppPrivacy } from './routes';
import organisationInfo from './org-info.json';

function App() {
  const [cookieBannerVisible, setCookieBannerVisibility] = useState(false);

  const openCookieBanner = () => setCookieBannerVisibility(true);
  const closeCookieBanner = () => setCookieBannerVisibility(false);

  useEffect(() => {
    const acceptedAt = Number(Cookies.get('nabla-accept-cookies-at'));

    if (!acceptedAt || acceptedAt <= config.cookieUpdateTimestamp) {
      openCookieBanner();
    }
  }, []);

  const title = 'Nabla';
  const description = 'Digital wallet, connecting all your friends.';
  const keywords =
    'nabla, token, crypto, cryptocurrency, blockchain, social media, social network, donates';

  const meta = (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <title>{title}</title>

      <meta name="application-name" content={title} />
      <meta name="msapplication-tooltip" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={organisationInfo.social.twitter} />
      <meta name="twitter:creator" content={organisationInfo.social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <meta property="og:url" content={organisationInfo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="snax.one" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );

  const schema = (
    <>
      <HiddenBlock
        itemProp="copyrightHolder"
        itemScope
        itemType="http://schema.org/Organization"
      >
        <span itemProp="name">{organisationInfo.name}</span>
        <span itemProp="email">{organisationInfo.email}</span>
        <link itemProp="url" href={organisationInfo.url} />
      </HiddenBlock>
      <HiddenBlock itemProp="copyrightYear">
        {organisationInfo.copyrightYear}
      </HiddenBlock>
    </>
  );
  return (
    <AppWrapper>
      <Router>
        <ScrollToTop>
          {meta}
          <GlobalStyles />
          <ResetStyles />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/help" component={Help} />
            <Route
              exact
              path="/mobile/privacy-policy"
              component={MobileAppPrivacy}
            />
          </Switch>
          {schema}
        </ScrollToTop>
      </Router>
      {cookieBannerVisible && <CookieBanner onClose={closeCookieBanner} />}
    </AppWrapper>
  );
}

export default App;
