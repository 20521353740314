import React from 'react';
import { Button } from '../Button';

import { WraperStyled, ContentStyled } from './styles';

export const Snackbar = ({ children, onCloseClick, buttonText, ...props }) => {
  return (
    <WraperStyled>
      <ContentStyled>
        <div>{children}</div>
        <Button onClick={onCloseClick} size="small" colorScheme="flat" outlined>
          Close
        </Button>
      </ContentStyled>
    </WraperStyled>
  );
};
