import React from 'react';
import Plx from 'react-plx';
import { Container, Section } from '../../components';
import styleConstants from '../../styles/style-constants';
import {
  ContentWrapper,
  ImageWrapper,
  SectionOffset,
} from './scroll-flow-send-styles';
import { PhoneFrameWrapper, PhoneMediaWrapper } from './landing-common-styles';

const bgParallaxData = [
  {
    start: 0,
    end: '#plx-start-1',
    properties: [
      {
        startValue: 0,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
  {
    start: '#plx-start-1',
    duration: '20vh',
    startOffset: '40vh',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '#plx-start-2',
    duration: '20vh',
    startOffset: '15vh',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
];

const styles = {
  width: '100vw',
  height: '100vh',
  backgroundColor: styleConstants.color.primary,
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: '-1',
};

export class ScrollFlowDemoSend extends React.Component {
  render() {
    return (
      <>
        <span id="plx-start-1" />
        <SectionOffset id="scroll-demo-send-flow">
          <Section>
            <Container>
              <ContentWrapper>
                <Plx parallaxData={bgParallaxData} style={styles} />
                <ImageWrapper>
                  <img src="./images/iphone-placeholder.png" alt="" />

                  <PhoneMediaWrapper>
                    <video
                      src="/video/screen-send.mp4"
                      autoPlay="1"
                      loop="1"
                      muted="1"
                      playsInline
                    />
                  </PhoneMediaWrapper>

                  <PhoneFrameWrapper>
                    <img src="./images/iphone-frame.png" alt="" />
                  </PhoneFrameWrapper>
                </ImageWrapper>
              </ContentWrapper>
            </Container>
          </Section>
        </SectionOffset>
        <span id="plx-start-2" />
      </>
    );
  }
}
