import React from 'react';
import {
  AnimFadeInParallax,
  Container,
  Headline,
  ParagraphIntro,
  Section,
  HeadlineBox,
} from '../../components';
import { PlatformsBlock } from './PlatformsBlock';
import { HeadlineAccent } from './landing-common-styles';
import { ContentWrapper, SectionOffset } from './scroll-platforms-styles';

export class ScrollPlatforms extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <ContentWrapper>
            <SectionOffset>
              <AnimFadeInParallax>
                <HeadlineBox ref={this.headlineRef} id="headline">
                  <Headline style={{ textAlign: 'center' }}>
                    Send money to anyone on
                    <HeadlineAccent>
                      <PlatformsBlock />
                    </HeadlineAccent>{' '}
                    in&nbsp;a&nbsp;few taps.
                  </Headline>
                  <ParagraphIntro style={{ textAlign: 'center' }}>
                    Fast and easy like a message.
                  </ParagraphIntro>
                </HeadlineBox>
              </AnimFadeInParallax>
            </SectionOffset>
          </ContentWrapper>
        </Container>
      </Section>
    );
  }
}
