import styled from 'styled-components';
import constants from '../../styles/style-constants';

export const Anchor = styled.a`
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  transition: 150ms ease-in;
  transition-property: color, opacity;
  color: ${props => (props.mimic ? 'currentColor' : constants.color.primary)};

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
    border: 0 solid transparent;
  }

  &,
  &:hover,
  &:focus {
    background-color: transparent;
    border: 0 solid transparent;
  }
`;
