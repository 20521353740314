import { createGlobalStyle } from 'styled-components';
import constants from './style-constants';

export const GlobalStyles = createGlobalStyle`    
  html,
  body {
    font-size: 100%;
    display: flex;
    align-items: stretch;
    overflow-x: hidden;
  }
  
  body {
    cursor: default;
    color: ${constants.textColor.body};
    font-family: ${constants.fontFamily.body};
    font-weight: ${constants.fontWeight.normal};
    font-size: ${constants.fontSize.body}px;
    line-height: 1.5;
    position: relative;
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: ${constants.colorBody};
  }

  #root {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
  }

  * {
    box-sizing: border-box;
  }

  ::selection {
    background: ${constants.paletteBlueGrey[100]} !important;
  }
`;
