import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 30px;

  img {
    height: 100%;
  }
`;
