import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Accordion,
  AccordionItemNotExpandable,
  Anchor,
  Container,
  Headline,
  ParagraphBody,
  RouteSection,
  RouteTitleBox,
  Section,
} from '../components';
import { Header, Footer } from '../containers';
import styleConstants from '../styles/style-constants';
import organisationInfo from '../org-info.json';

export const Help = ({ ...props }) => {
  const currentLink = props.serverUrl || window.location;
  let position = 0;

  function calculatePosition() {
    position++;
    return position;
  }

  const title = `Frequently Asked Questions | Nabla`;
  const description = 'Frequently Asked Questions (FAQ) of Nabla.';
  const keywords = 'nabla, faq, help, questions';
  const publishedOn = 'July 16, 2019';
  const modifiedOn = 'July 16, 2019';

  const meta = (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={currentLink} />
      <meta property="og:type" content="article" />
      <meta property="article:published_time" content={publishedOn} />
      <meta property="article:modified_time" content={modifiedOn} />
      <meta property="article:author" content={organisationInfo.name} />
      <meta property="article:tag" content={keywords} />
    </Helmet>
  );

  return (
    <>
      {meta}
      <Header logoFullColor logoHref="/" />
      <Section spread>
        <Container>
          <RouteSection>
            <RouteTitleBox>
              <Headline>Help</Headline>
            </RouteTitleBox>
            <Accordion>
              <AccordionItemNotExpandable
                title="How can I get started?"
                id="get-started"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  You need a smartphone with iOS or Android. Install Nabla
                  Wallet from App Store or Google Play and follow the
                  instructions.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="How can I use Nabla?"
                id="how-to-use"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  You can use Nabla to send money to anyone using their name in
                  supported social platforms. Money is sent using Snax
                  blockchain in the form of ‘stable coins’: the special tokens,
                  which are fully backed by a relevant currency (USD, EUR, etc.)
                  in a one-to-one ratio.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="What is Snax?"
                id="what-is-snax"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  Snax is an independent public blockchain, which has SNAX as a
                  basic utility token. You can learn more about Snax blockchain
                  here{' '}
                  <Anchor href="https://snax.one/faq" target="_blank">
                    https://snax.one/faq
                  </Anchor>
                  . There will be only SNAX transfer option in Nabla in the
                  beginning.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="Which social platforms are supported for transfers?"
                id="platforms-support"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  For now, Twitter and Steem are supported, but the list is
                  expanding, and the most part of popular social platforms and
                  messengers will be added in future.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="What if I don’t use social media?"
                id="no-social-media"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  You can receive transfers directly by your account name in
                  Nabla.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="When will the money sending option be available?"
                id="when-money"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>In the second half of 2019.</ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="Will Nabla charge fee for money transfers?"
                id="transfers-fee"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  The transfer fee in Nabla will be equal to 0.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="Do I need to provide any documents for KYC?"
                id="kyc-documents"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  No, you can transfer money inside Nabla without providing any
                  documents. But if you want to deposit or withdraw money using
                  your bank account or credit card, you will have to pass a KYC
                  procedure. Your KYC information will be available only for a
                  financial provider and will not be published.
                </ParagraphBody>
              </AccordionItemNotExpandable>

              <AccordionItemNotExpandable
                title="In which countries can I use Nabla?"
                id="which-countries"
                position={calculatePosition()}
                link={currentLink}
              >
                <ParagraphBody>
                  Since Nabla is powered by a decentralized blockchain, you can
                  use it in any place in the world, if you are connected to the
                  Internet. But if some country restricts public blockchains,
                  Nabla can be unavailable in App Store or Google Play in this
                  country.
                </ParagraphBody>
              </AccordionItemNotExpandable>
            </Accordion>
          </RouteSection>
        </Container>
      </Section>
      <Footer style={{ color: styleConstants.textColor.medium }} />
    </>
  );
};

export default Help;
