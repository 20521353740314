import React from 'react';
import { Icon } from './Icon';

export const IconTwitter = props => (
    <Icon {...props}>
        <svg viewBox="0 0 26 26">
            <path d="M8.3,23.4c9.6,0,14.9-8,14.9-14.9c0-0.2,0-0.5,0-0.7c1-0.7,1.9-1.7,2.6-2.7c-1,0.4-2,0.7-3,0.8c1.1-0.7,1.9-1.7,2.3-2.9
            c-1,0.6-2.2,1-3.3,1.3c-2-2.1-5.3-2.2-7.4-0.2c-1.4,1.3-1.9,3.2-1.5,5C8.6,8.8,4.7,6.8,2,3.6C0.6,6,1.3,9,3.6,10.6
            c-0.8,0-1.7-0.2-2.4-0.7c0,0,0,0,0,0.1c0,2.5,1.8,4.6,4.2,5.1c-0.8,0.2-1.6,0.2-2.4,0.1c0.7,2.1,2.7,3.6,4.9,3.6
            c-1.9,1.5-4.2,2.3-6.5,2.2c-0.4,0-0.8,0-1.2-0.1C2.6,22.6,5.4,23.4,8.3,23.4"/>
        </svg>
    </Icon>
);
