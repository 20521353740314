import React from 'react';
import TrackVisibility from 'react-on-screen';
import {
  AnimFadeInParallax,
  AnimFadeInUp,
  Container,
  Headline,
  HeadlineBox,
  ParagraphIntro,
  Section,
  SectionPaddingBox,
  TransactionBubble,
} from '../../components';
import { HeadlineAccent } from './landing-common-styles';
import { BubblesGrid, SectionWrapper } from './scroll-digital-cash-styles';

export const ScrollDigitalCash = () => (
  <SectionWrapper>
    <Section>
      <Container>
        <SectionPaddingBox>
          <AnimFadeInParallax>
            <HeadlineBox>
              <Headline>
                Your <HeadlineAccent>digital cash</HeadlineAccent> for
                a&nbsp;new global economy.
              </Headline>
              <ParagraphIntro>
                Transfer money oblivious to borders, with no fees.
              </ParagraphIntro>
            </HeadlineBox>
          </AnimFadeInParallax>

          <TrackVisibility partialVisibility>
            {({ isVisible }) =>
              isVisible && (
                <BubblesGrid>
                  <AnimFadeInUp
                    startAnimation
                    items={[
                      <TransactionBubble
                        amount="10 ∇USD"
                        imagePath="/images/ava-wwf.jpg"
                        message="For the future of our planet"
                        platform="twitter"
                      />,
                      <TransactionBubble
                        amount="100 ∇USD"
                        imagePath="/images/ava-woman.png"
                        platform="nabla"
                        message="Happy Mother's day!"
                      />,
                      <TransactionBubble
                        amount="2 ∇USD"
                        imagePath="/images/ava-reddit.png"
                        message="Keep creating great posts!"
                        platform="reddit"
                        recipient="mtimetraveller"
                      />,
                      <TransactionBubble
                        amount="14 ∇USD"
                        imagePath="/images/ava-girl.jpg"
                        message="Morning coffee break"
                        platform="twitter"
                        recipient="joahnClark"
                      />,
                    ]}
                  />
                </BubblesGrid>
              )
            }
          </TrackVisibility>
        </SectionPaddingBox>
      </Container>
    </Section>
  </SectionWrapper>
);
