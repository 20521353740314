import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';
import {
  addSectionPaddingBottom,
  addSectionPaddingTop,
  media,
} from '../../styles/style-mixins';

export const ScrollWrapper = styled.div`
  background-image: url('/images/bg-gradient-top.png');
  background-repeat: no-repeat;
  background-size: 100% 50%;

  ${media.small`
    background-size: 100% 100%;  
  `}
`;

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${addSectionPaddingBottom}
  ${addSectionPaddingTop}

  ${media.small`
    text-align: left;
    align-items: flex-start;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
`;

export const ContentGrid = styled.div`
  flex-grow: 1;

  > * {
    display: grid;
    align-items: center;
    justify-content: space-around;
    grid-gap: ${styleConstants.baseModule * 4}px;

    @media only screen and (min-width: ${styleConstants.mq.small}) {
      grid-template-columns: 1fr 220px;
    }

    @media only screen and (min-width: ${styleConstants.mq.small}) {
      grid-template-columns: 1fr 320px;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 260px;
  margin: auto;
  position: relative;

  img {
    width: 100%;
  }

  ${media.small`
    width: 100%; 
  `}
`;
