import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

const Path0 = styled.path`
  fill: #fabb09;
`;

const Path1 = styled.path`
  fill: #e09b05;
`;

const Path2 = styled.path`
  fill: #ffe28a;
`;

const Path3 = styled.path`
  fill: #fbbf0f;
`;

export const IconSnax = props => (
  <Icon {...props}>
    <svg viewBox="0 0 36 54" x="0px" y="0px">
      <g>
        <Path0
          d="M19.86,10.54c-0.37-0.16-0.75-0.29-1.14-0.38c-4.94-1.23-6.95,2.21-6.95,2.21
					c-3.12-3.9-4.88-8.86,2.99-10.8C21.74-0.16,27.92,5.4,29.2,6.65c0.16,0.16,0.24,0.24,0.24,0.24c8.26,8.46,6.86,20.55,3.32,29.18
					c-3.9,9.52-13.11,15.03-15.51,16.48c-0.47,0.29-0.99,0.52-1.53,0.7c0.43-0.16,7.24-2.92,8-13.17c0.1-1.31,0.03-2.67-0.16-4.04
					c1.76-2.33,3.94-7.81,3.87-13.08C27.36,18.11,24.21,12.37,19.86,10.54z"
        />
        <Path1
          d="M27.42,22.97c0.07,5.27-2.1,10.75-3.87,13.08c-0.29-2.08-0.87-4.19-1.63-6.27
					c0.04-0.06,1.36-1.87,2.17-8.37c0.81-6.51-4.1-10.75-4.24-10.87C24.21,12.37,27.36,18.11,27.42,22.97z"
        />
        <Path2
          d="M0.7,11.94c-0.75-3.02,0.23-4.52,1.86-6.5c2.7-3.29,12.63-9.46,24.02-1.04c0.96,0.71,1.84,1.46,2.63,2.25
					c-1.28-1.25-7.46-6.81-14.45-5.09c-7.86,1.94-6.11,6.9-2.99,10.8c2.15,2.68,7.37,9.8,10.17,17.42c0.76,2.08,1.34,4.2,1.63,6.27
					c0.19,1.37,0.25,2.73,0.16,4.04c-0.75,10.25-7.57,13.01-8,13.17c-0.01,0-0.02,0.01-0.02,0.01c-1.97,0.66-4.34,0.69-6.63,0.39
					c4.78-0.28,8.28-4.85,8.82-11.48c0.54-6.63-4.84-16.55-11.06-22.74c-5.06-5.03-5.94-6.92-6.1-7.39c0-0.02-0.01-0.03-0.01-0.05
					c0-0.01,0-0.02-0.01-0.04C0.71,11.96,0.7,11.95,0.7,11.94C0.7,11.94,0.7,11.94,0.7,11.94z"
        />
        <Path1
          d="M6.83,19.44c6.22,6.19,11.6,16.11,11.06,22.74c-0.54,6.63-4.04,11.2-8.82,11.48
					c-0.27-0.04-0.55-0.07-0.82-0.12c-1.53-0.25-2.63-1.08-3.37-1.86c-0.75-0.8-1.14-1.89-1.07-2.98c0.06-1,0.14-2.4,0.22-3.62
					c0.03-0.43,0.13-0.8,0.28-1.11c-0.57,1.23,0.15,3.93,3.52,3.35c3.45-0.6,4.32-7.11,4.35-7.37c0-0.01,0-0.01,0-0.01
					c0.16-0.66,0.32-2.49-1.06-6.46c-1.82-5.25-6.25-12.02-9.01-17.81c-0.68-1.42-1.13-2.6-1.39-3.61
					C0.88,12.52,1.76,14.41,6.83,19.44z"
        />
        <Path0
          d="M12.09,40.23c0,0,0.05-0.09,0.1-0.29c-0.03,0.26-0.9,6.77-4.35,7.37c-3.36,0.59-4.08-2.12-3.52-3.35
					c0.01-0.03,0.03-0.06,0.04-0.09c0.01-0.02,0.02-0.05,0.04-0.07c0.67-1.14,2.13-1.49,3.49-1.79
					C9.68,41.63,12.09,40.23,12.09,40.23z"
        />
        <Path3 d="M4.36,43.87c-0.01,0.03-0.03,0.06-0.04,0.09C4.33,43.93,4.35,43.9,4.36,43.87z" />
        <Path3 d="M0.71,11.97c0,0.01,0.01,0.02,0.01,0.04C0.71,11.99,0.71,11.98,0.71,11.97z" />
        <Path3 d="M0.7,11.95c0,0.01,0,0.02,0.01,0.02C0.7,11.96,0.7,11.95,0.7,11.95z" />
      </g>
    </svg>
  </Icon>
);
