import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';
import {
  addSectionPaddingTop,
  addSectionPaddingBottom,
  media,
} from '../../styles/style-mixins';

export const PaddingBox = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${props => !props.removeTop && addSectionPaddingTop}

  ${props => !props.removeBottom && addSectionPaddingBottom}
`;

export const SectionContentWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 87.5%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    max-width: 692px;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    max-width: 980px;
  }
`;
