import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';
import { media } from '../../styles/style-mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 15em;
  width: max-content;
  margin-bottom: ${styleConstants.baseModule * 1.5}px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: ${styleConstants.baseModule * 1.5}px;
`;

export const Avatar = styled.div`
  border-radius: ${styleConstants.borderRadius}px;
  background-color: ${styleConstants.paletteBlueGrey[50]};
  background-repeat: no-repeat;
  background-size: cover;
  width: 90px;
  height: 90px;
  border: 1px solid ${styleConstants.paletteBlueGrey[50]};
    ${props => props.imagePath && `background-image: url(${props.imagePath});`}
    ${media.small`
    width: 120px;
    height: 120px;
  `} ${media.medium`
    width: 140px;
    height: 140px;
  `};
`;

export const PlatformIcon = styled.div`
  background-color: ${props =>
    props.platform
      ? styleConstants.paletteBrand[props.platform]
      : styleConstants.paletteBlueGrey[700]};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;

const cornerHeight = 12;
export const MessageWrapper = styled.div`
  padding: 1em 1.25em;
  border-radius: ${styleConstants.borderRadius}px;
  background-color: ${props =>
    props.platform
      ? styleConstants.paletteBrand[props.platform]
      : styleConstants.paletteBlueGrey[100]};
  color: ${props =>
    props.platform ? '#fff' : styleConstants.paletteBlueGrey[700]};
  position: relative;
  margin-bottom: ${cornerHeight}px;

  &::after {
    display: block;
    content: '';
    background-color: inherit;
    width: 20px;
    height: ${cornerHeight}px;
    position: absolute;
    bottom: 1px;
    left: 15px;
    transform: translateY(100%);
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  p {
    margin: 0;
  }
`;

export const AmountWrapper = styled.div`
  font-size: ${styleConstants.fontSize.body2}px;
  line-height: 1.19;
  font-weight: ${styleConstants.fontWeight.bold};
`;
