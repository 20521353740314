import React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useKey } from '../hooks';

import {
  Snackbar,
  ParagraphBody,
  ParagraphBodySmall,
  Anchor,
  Button,
  Row as _Row,
} from '../components';

const Row = styled(_Row)`
  & + & {
    margin-top: 0.5em;
    padding-top: 0;
  }
`;

const CookieBanner = ({ onClose }) => {
  useKey(() => handleAcceptCookies(), {
    detectKeys: [27],
  });

  const handleAcceptCookies = () => {
    const timestamp = Math.floor(Date.now() / 1000);
    Cookies.set('nabla-accept-cookies-at', timestamp);
    onClose();
  };

  return (
    <Snackbar onCloseClick={handleAcceptCookies}>
      <Row>
        <ParagraphBody style={{ marginBottom: '0.25em' }}>
          <strong>Notice</strong>
        </ParagraphBody>
        <ParagraphBodySmall style={{ marginTop: 0 }}>
          This website or its third-party tools use cookies, which are necessary
          to its functioning. By closing this banner, scrolling this page,
          clicking a link or continuing to browse otherwise, you agree to the
          use of cookies.
          {/* See{' '}
          <Anchor to="cookie-policy" target="_blank">
            Cookie Policy
          </Anchor>{' '}
          for more info. */}
        </ParagraphBodySmall>
      </Row>
    </Snackbar>
  );
};

export default CookieBanner;
