import styled from 'styled-components';
import { Headline } from './Headline';
import styleConstants from '../../styles/style-constants';

export const ParagraphIntro = styled.p`
  font-size: 19px;
  line-height: 1.19;
  font-weight: 600;
  color: ${styleConstants.textColor.medium};
  margin: 0;

  ${Headline} + & {
    margin-top: 34px;
  }

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 21px;

    ${Headline} + & {
      margin-top: 34px;
    }
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    font-size: 24px;

    ${Headline} + & {
      margin-top: 55px;
    }
  }
`;
