import styled from 'styled-components';
import { media } from '../../styles/style-mixins';
import styleConstants from '../../styles/style-constants';

export const RouteHeading3 = styled.h4`
  font-size: 16px;
  line-height: 1.19;
  font-weight: ${styleConstants.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.2;
  margin: 0;

  ${media.small`
    line-height: 1.2;
    font-size: 17px;
  `}

  ${media.medium`
    font-size: 18px;
  `}

  & + &,
  p + & {
    margin-top: 0.8em;
  }
`;
