import styled from 'styled-components';
import constants from '../../styles/style-constants';

const iconSize = 44;
const iconImgSize = 24;

export const WraperStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  max-width: 100vw;
  width: 100%;
  background-color: ${constants.paletteBlueGrey[25]};
  color: ${constants.textColor.body};
  padding: 0.75em 0.5em;
  border-top: 1px solid ${constants.paletteBlueGrey[50]};

  @media only screen and (min-width: ${constants.mq.small}) {
    padding: 1.25em 1.5em;
  }
`;

export const ContentStyled = styled.div`
  flex-grow: 1;
  font-size: ${constants.fontSize.small}px;
  line-height: 1.1;
  margin: auto;
  max-width: 52em;

  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr auto;
  align-items: center;

  @media only screen and (min-width: ${constants.mq.small}) {
    font-size: ${constants.fontSize.body}px;
    line-height: 1.33;
  }
`;
