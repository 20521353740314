import styled from 'styled-components';

const sizeOuter = '46px';
const sizeIcon = '24px';

export const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${sizeOuter};
  height: ${sizeOuter};
  color: currentColor;
  border-radius: 50%;
  border: 1px solid currentColor;
  opacity: 0.6;
  cursor: pointer;
  transition: 150ms;
  transition-property: opacity;

  &:hover {
    opacity: 1;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${sizeIcon};
  height: ${sizeIcon};
`;
