import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const Wrapper = styled.a`
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-decoration: none;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    height: 52px;
  }

  img {
    height: 100%;
  }
`;

export const Button = styled.div`
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  padding: 0 1em;
  font-weight: ${styleConstants.fontWeight.bold};
  height: inherit;
  display: flex;
  align-items: center;
  transition: 150ms ease-in;
  transition-property: background-color;

  &:hover {
    background-color: rgba(0, 0, 0, 0.85);
  }
`;
