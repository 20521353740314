import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const RouteBodyHeader = styled.h2`
  font-size: 19px;
  line-height: 1.21;
  font-weight: 600;
  margin: 37px 0 20px;

  &:first-child {
    margin-top: 0;
  }

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 24px;
    line-height: 1.13353;
  }
`;
