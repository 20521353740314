import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { AccordionItem as AccordionItemElement } from '../components';

const AccordionItem = props => {
  const isHashMatchId = props.location.hash.substring(1) === props.id;

  const [expanded, setExpanded] = useState(isHashMatchId);

  useEffect(() => {
    if (isHashMatchId) {
      setExpanded(true);
    }
  }, [isHashMatchId]);

  const toggleExpand = () => {
    const hash = `#${props.id}`;
    setExpanded(!expanded);
    if (expanded) {
      props.history.push();
    } else {
      props.history.push(hash);
    }
  };

  return (
    <AccordionItemElement
      onTitleClick={toggleExpand}
      expanded={expanded}
      {...props}
    />
  );
};

export default withRouter(AccordionItem);
