import styled from 'styled-components';
import {
  media,
  addSectionPaddingTop,
  addSectionPaddingBottom,
} from '../../styles/style-mixins';
import styleConstants from '../../styles/style-constants';

export const SectionOffset = styled.div`
  margin-top: calc(-10vh - ${styleConstants.components.section.mq.paddingTop});

  ${media.small`
    margin-top: calc(-20vh - ${
      styleConstants.components.section.mq.small.paddingTop
    });
  `};

  ${media.medium`
    margin-top: calc(-20vh - ${
      styleConstants.components.section.mq.medium.paddingTop
    });
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  ${addSectionPaddingBottom};
  ${addSectionPaddingTop};
`;

export const ImageWrapper = styled.div`
  width: 90vw;
  max-width: 320px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  > img {
    width: 100%;
    margin: auto;
  }

  ${media.small`
    max-width: 100%;
    height: 90vh;
    width: auto;

    > img {
      height: 100%;
      width: auto;
    }
  `}
`;

export const FrameWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  > video,
  > img {
    width: 100%;
  }
`;

export const MediaWrapper = styled.div`
  position: absolute;
  width: 85%;
  top: 3.5%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  > video,
  > img {
    width: 100%;
  }
`;
