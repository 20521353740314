import styled from 'styled-components';
import { media } from '../../styles/style-mixins';

export const ParagraphList = styled.ul`
  font-size: 16px;
  line-height: 1.19;
  margin: 0;

  ${media.small`
    line-height: 1.2;
    font-size: 17px;
  `}

  ${media.medium`
    font-size: 18px;
  `}

  & + &,
  p + & {
    margin-top: 0.8em;
  }
`;
