import styled from 'styled-components';
import constants from '../../styles/style-constants';

const propTypes = {};

const defaultProps = {};

export const Row = styled.div`
  width: 100%;

  & + & {
    padding-top: ${constants.components.bodyPadX}px;
  }
`;

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;
