import styleConstants from './style-constants';
import { css } from 'styled-components';

export const media = Object.keys(styleConstants.mq).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${styleConstants.mq[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const addSectionPaddingTop = `
  padding-top: ${styleConstants.components.section.mq.paddingTop};

  ${media.small`
    padding-top: ${styleConstants.components.section.mq.small.paddingTop};
  `}

  ${media.medium`
    padding-top: ${styleConstants.components.section.mq.medium.paddingTop};
  `}
`;

export const addSectionPaddingBottom = `
  padding-bottom: ${styleConstants.components.section.mq.paddingBottom};

  ${media.small`
    padding-bottom: ${styleConstants.components.section.mq.small.paddingBottom};
  `}

  ${media.medium`
    padding-bottom: ${
      styleConstants.components.section.mq.medium.paddingBottom
    };
  `}
`;
