import styled from 'styled-components';
import {
  addSectionPaddingBottom,
  addSectionPaddingTop,
  media,
} from '../../styles/style-mixins';

export const SectionWrapper = styled.div`
  ${addSectionPaddingTop};
  ${addSectionPaddingBottom};
`;

// eslint-disable-next-line
export const BubblesGrid = styled.div`
  width: 100%;

  > * {
    display: grid;
    grid-gap: 1em;
  }

  .AnimFadeInUp-item-box {
    max-width: 15em;
  }

  .AnimFadeInUp-item-box:nth-child(2n) {
    margin-left: auto;
  }

  ${media.xxsmall`
    .AnimFadeInUp-item-box:first-child {
      margin-left: auto;
    }

    .AnimFadeInUp-item-box:nth-child(2) {
      margin-top: -2em;
      margin-left: 0;
    }

    .AnimFadeInUp-item-box:nth-child(3) {
      margin-left: auto;
      margin-right: 3em;
    }

    .AnimFadeInUp-item-box:nth-child(4) {
      margin-left: 2em;
    }
  `};

  ${media.xsmall`
    > * {
      grid-template-columns: repeat(2, 1fr);
    }

    .AnimFadeInUp-item-box:first-child {
      margin-top: 1.5em;
      margin-left: 0;
    }

    .AnimFadeInUp-item-box:nth-child(2) {
      margin-top: 10em;
      margin-left: auto;
    }

    .AnimFadeInUp-item-box:nth-child(3) {
      margin-left: 6em;
      margin-top: -4em;
      margin-right: auto;
    }

    .AnimFadeInUp-item-box:nth-child(4) {
      margin-top: 13em;
      margin-left: 0;
    }
  `}

  ${media.medium`
    > * {
      grid-template-columns: repeat(4, 1fr);
    }

    .AnimFadeInUp-item-box:first-child {
      margin-top: 1.5em;
      margin-left: 0;
    }

    .AnimFadeInUp-item-box:nth-child(2) {
      margin-top: 20em;
      margin-left: 0;
    }

    .AnimFadeInUp-item-box:nth-child(3) {
      margin-left: -3em;
      margin-top: 0;
      margin-right: auto;
    }

    .AnimFadeInUp-item-box:nth-child(4) {
      margin-top: 13em;
      margin-left: 0;
    }
  `}
`;
