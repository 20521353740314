import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import constants from '../../styles/style-constants';

const Wrapper = styled.li`
  display: flex;
  align-items: stretch;
  position: relative;

  @media only screen and (min-width: ${constants.mq.small}) {
    & + &::before {
      content: '•';
      display: block;
      color: ${constants.color.primary};
      font-size: 0.8em;
      vertical-align: middle;
      margin-top: -1px;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }
  }
`;

const Anchor = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: ${constants.fontSize.body}px;
  line-height: 1;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 150ms ease-in;
  transition-property: border, color, opacity, background-color;
  letter-spacing: 0.01em;
  color: currentColor;
  opacity: 0.8;

  > span {
    border-bottom: 1px solid transparent;
    transition: 150ms ease-in;
    transition-property: border, color, opacity;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    opacity: 1;

    > span {
      border-bottom-color: currentColor;
    }
  }

  &.active {
    &,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
`;

export const MenuItem = ({ to, asComponent, ...props }) => (
  <Wrapper>
    <Anchor to={to} href={props.href} as={asComponent} {...props}>
      <span>{props.children}</span>
    </Anchor>
  </Wrapper>
);
