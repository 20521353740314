import React from 'react';
import {
  AnimFadeIn,
  BetaTestButton,
  Container,
  AppStoreDownloadButton,
  GooglePlayDownloadButton,
  Headline,
  HeadlineBox,
  Logo,
  Row,
  Section,
  ParagraphBody,
} from '../../components';
import { Header } from '../../containers';
import organisationInfo from '../../org-info.json';
import {
  ContentWrapper,
  ContentGrid,
  ImageWrapper,
  ScrollWrapper,
  TextWrapper,
} from './scroll-welcome-styles.js';
import {
  BadgeGrid,
  HeadlineAccent,
  PhoneFrameWrapper,
  PhoneMediaWrapper,
  TestButtonWrapper,
} from './landing-common-styles';

export const ScrollWelcome = () => (
  <ScrollWrapper>
    <Section>
      <Header>
        <Logo />
      </Header>

      <ContentWrapper>
        <Container>
          <ContentGrid>
            <AnimFadeIn
              startAnimation
              staggerDelay={10}
              items={[
                <TextWrapper>
                  <HeadlineBox>
                    <Headline>
                      Digital wallet, connecting all{' '}
                      <HeadlineAccent>your friends.</HeadlineAccent>
                    </Headline>
                  </HeadlineBox>
                  <BadgeGrid>
                    <AppStoreDownloadButton link={organisationInfo.stores.apple}/>

                    <GooglePlayDownloadButton
                      link={organisationInfo.stores.google}
                    />
                  </BadgeGrid>
                </TextWrapper>,
                <ImageWrapper>
                  <img src="./images/iphone-placeholder.png" alt="" />

                  <PhoneMediaWrapper>
                    <video
                      src="/video/transactions-carousel.mp4"
                      autoPlay="1"
                      loop="1"
                      muted="1"
                      playsInline="1"
                    />
                  </PhoneMediaWrapper>

                  <PhoneFrameWrapper>
                    <img src="./images/iphone-frame.png" alt="" />
                  </PhoneFrameWrapper>
                </ImageWrapper>,
              ]}
            />
          </ContentGrid>
        </Container>
      </ContentWrapper>
    </Section>
  </ScrollWrapper>
);
