import React from 'react';
import posed from 'react-pose';

const itemAnimDuration = 1000;

const Wrapper = posed.div({
  visible: {
    opacity: 1,
    beforeChildren: true,
    staggerChildren: ({ staggerDelay }) => staggerDelay
  },
  hidden: { opacity: 0 },
  props: { staggerDelay: 400 }
});

const Item = posed.div({
  visible: {
    opacity: 1,
    transition: {
      opacity: {
        duration: itemAnimDuration
      }
    }
  },
  hidden: { opacity: 0 }
});

export const AnimFadeIn = ({ startAnimation, items, staggerDelay }) => {
  return (
    <Wrapper
      pose={startAnimation ? 'visible' : 'hidden'}
      initialPose="hidden"
      staggerDelay={staggerDelay}
    >
      {items.map((item, idx) => (
        <div className="AnimFadeInUp-item-box" key={idx}>
          <Item>{item}</Item>
        </div>
      ))}
    </Wrapper>
  );
};
