import React from 'react';
import styled from 'styled-components';
import { IconTwitter, SocialButton } from '../components';
import styleConstants from '../styles/style-constants';

const Wrapper = styled.footer`
  color: currentColor;
  text-align: right;
  padding: ${styleConstants.baseModule * 2}px ${styleConstants.baseModule * 2}px;
  display: grid;
  grid-gap: ${styleConstants.baseModule * 2}px;
`;

const CopyrightWrapper = styled.div`
  font-size: ${styleConstants.fontSize.small}px;
  text-align: right;
`;

export const Footer = ({ children, ...props }) => (
  <Wrapper {...props}>
    <div>
      <SocialButton href="https://twitter.com/nablaapp" target="_blank">
        <IconTwitter />
      </SocialButton>
    </div>
    <CopyrightWrapper>Nabla, 2019</CopyrightWrapper>
  </Wrapper>
);
