import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const HeadlineAccent = styled.span`
  color: ${styleConstants.color.primary};
`;

export const BadgeGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: ${styleConstants.baseModule}px;
    margin-bottom: ${styleConstants.baseModule / 2}px;
  }
`;

export const TestButtonWrapper = styled.div`
  display: grid;
  grid-gap: ${styleConstants.baseModule * 1.5}px;
  align-items: flex-start;
  margin-top: ${styleConstants.baseModule * 3}px;
`;

export const PhoneFrameWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  > video,
  > img {
    width: 100%;
  }
`;

export const PhoneMediaWrapper = styled.div`
  position: absolute;
  width: 85%;
  top: 3.5%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  > video,
  > img {
    width: 100%;
  }
`;
