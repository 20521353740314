import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const RouteHeading1 = styled.h3`
  font-size: 24px;
  line-height: 1.13353;
  font-weight: 600;
  margin: 1.5em 0 1.2em;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 34px;
    line-height: 1.1;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    font-size: 42px;
    line-height: 1.08365;
  }
`;
