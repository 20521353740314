import styled from 'styled-components';
import { addSectionPaddingTop } from '../../styles/style-mixins';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  ${addSectionPaddingTop};
`;

export const SectionOffset = styled.div`
  padding-bottom: 20vh;
`;
