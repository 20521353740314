import React from 'react';
import styled from 'styled-components';
import { IconArrowExpand, HiddenBlock } from '../../components';
import constants from '../../styles/style-constants';
import { media } from '../../styles/style-mixins';
import orgInfo from '../../org-info.json';

const siconSize = 32;
const titleLineHeight = 1.25;
const titlePadYEM = 1;

const Item = styled.li`
  position: relative;
  padding: 0;
  margin: 0;
`;

const Header = styled.button`
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  font-size: ${constants.fontSize.body2}px;
  font-weight: ${constants.fontWeight.bold};
  padding: ${titlePadYEM}em 0;
  line-height: ${titleLineHeight};

  ${media.small`
    font-size: ${constants.fontSize.body3}px;
  `};

  &,
  &:hover,
  &:focus {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
  }
`;

const Title = styled.div`
  color: ${props =>
    props.expanded ? constants.color.primary : constants.textColor.body};
  flex-grow: 1;
  transition: 150ms ease-in;
  transition-property: color;
`;

const Content = styled.div`
  color: ${constants.textColor.body};
  font-size: ${constants.fontSize.body}px;
  line-height: 1.5;
  padding-bottom: ${constants.baseModule * 4}px;
`;

export const AccordionItemNotExpandable = ({ title, children, ...props }) => {
  const schema = (
    <>
      <link itemProp="url" href={props.link + '#' + props.id} />
      <HiddenBlock itemProp="name">{title}</HiddenBlock>
      <HiddenBlock itemProp="text">{title}</HiddenBlock>
      <HiddenBlock itemProp="position">{props.position || '1'}</HiddenBlock>
      <HiddenBlock itemProp="answerCount">1</HiddenBlock>
      <HiddenBlock itemProp="dateCreated">
        {props.dateCreated || '2019-07-16'}
      </HiddenBlock>
      <HiddenBlock
        itemProp="author"
        itemScope
        itemType="http://schema.org/Organization"
      >
        <span itemProp="name">{orgInfo.name}</span>
        <span itemProp="email">{orgInfo.email}</span>
        <link itemProp="url" href={orgInfo.url} />
      </HiddenBlock>
      <HiddenBlock
        itemProp="acceptedAnswer"
        itemScope
        itemType="http://schema.org/Answer"
      >
        <div itemProp="text">{children}</div>
        <link itemProp="url" href={props.link + '#' + props.id} />
        {props.dateCreated && (
          <HiddenBlock itemProp="dateCreated">{props.dateCreated}</HiddenBlock>
        )}
        <HiddenBlock
          itemProp="author"
          itemScope
          itemType="http://schema.org/Organization"
        >
          <span itemProp="name">{orgInfo.name}</span>
          <span itemProp="email">{orgInfo.email}</span>
          <link itemProp="url" href={orgInfo.url} />
        </HiddenBlock>
      </HiddenBlock>
    </>
  );

  return (
    <Item
      {...props}
      itemScope
      itemProp="itemListElement"
      itemType="http://schema.org/Question"
      additionalType="http://schema.org/ListItem"
    >
      <Header id={'faq-' + props.id} className="faq-item-button">
        <Title itemProp="name">{title}</Title>
      </Header>
      <Content>{children}</Content>
      {schema}
    </Item>
  );
};
