import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Anchor,
  Container,
  Headline,
  HeadlineMeta,
  ParagraphBody,
  ParagraphList,
  RouteHeading1,
  RouteHeading2,
  RouteHeading3,
  RouteSection,
  RouteTitle,
  RouteTitleBox,
  Section,
} from '../components';
import { Header, Footer } from '../containers';
import styleConstants from '../styles/style-constants';
import organisationInfo from '../org-info.json';

export const MobileAppPrivacy = ({ ...props }) => {
  const currentLink = props.serverUrl || window.location;
  const title = `Privacy Policy | Nabla Mobile Wallet`;
  const description = 'Privacy Policy of Nabla Mobile Wallet.';
  const keywords = 'nabla, app, privacy';
  const publishedOn = 'July 17, 2019';
  const modifiedOn = 'July 17, 2019';

  const meta = (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={currentLink} />
      <meta property="og:type" content="article" />
      <meta property="article:published_time" content={publishedOn} />
      <meta property="article:modified_time" content={modifiedOn} />
      <meta property="article:author" content={organisationInfo.name} />
      <meta property="article:tag" content={keywords} />
    </Helmet>
  );

  return (
    <>
      {meta}
      <Header logoFullColor logoHref="/" />
      <Section spread>
        <Container>
          <RouteSection>
            <RouteTitleBox>
              <Headline>Privacy Policy</Headline>
              <HeadlineMeta>Effective date: July 17, 2019</HeadlineMeta>
            </RouteTitleBox>
            <ParagraphBody>
              Nabla ("us", "we", or "our") operates the Nabla mobile application
              (the "Service"). This page informs you of our policies regarding
              the collection, use, and disclosure of personal data when you use
              our Service and the choices you have associated with that data.{' '}
            </ParagraphBody>
            <ParagraphBody>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions.
            </ParagraphBody>

            <RouteTitleBox>
              <RouteTitle>Information Collection And Use</RouteTitle>
            </RouteTitleBox>
            <ParagraphBody>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </ParagraphBody>

            <RouteHeading1>Types of Data Collected</RouteHeading1>

            <RouteHeading2>Personal Data</RouteHeading2>
            <ParagraphBody>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
            </ParagraphBody>
            <ParagraphList>
              <li>Cookies and Usage Data</li>
            </ParagraphList>

            <RouteHeading2>Usage Data</RouteHeading2>
            <ParagraphBody>
              When you access the Service by or through a mobile device, we may
              collect certain information automatically, including, but not
              limited to, the type of mobile device you use, your mobile device
              unique ID, the IP address of your mobile device, your mobile
              operating system, the type of mobile Internet browser you use,
              unique device identifiers and other diagnostic data ("Usage
              Data").
            </ParagraphBody>

            <RouteHeading2>Tracking and Cookies Data</RouteHeading2>
            <ParagraphBody>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </ParagraphBody>
            <ParagraphBody>
              Cookies are files with small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Tracking technologies also
              used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </ParagraphBody>
            <ParagraphBody>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </ParagraphBody>
            <ParagraphBody>Examples of Cookies we use:</ParagraphBody>
            <ParagraphList>
              <li>
                <strong>Session Cookies.</strong> We use Session Cookies to
                operate our Service.
              </li>
              <li>
                <strong>Preference Cookies.</strong> We use Preference Cookies
                to remember your preferences and various settings.
              </li>
              <li>
                <strong>Security Cookies.</strong> We use Security Cookies for
                security purposes.
              </li>
            </ParagraphList>

            <RouteHeading1>Use of Data</RouteHeading1>
            <ParagraphBody>
              Nabla uses the collected data for various purposes:
            </ParagraphBody>
            <ParagraphList>
              <li>To provide and maintain the Service</li>
              <li>To notify you about changes to our Service</li>
              <li>
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li>To provide customer care and support</li>
              <li>
                To provide analysis or valuable information so that we can
                improve the Service
              </li>
              <li>To monitor the usage of the Service</li>
              <li>To detect, prevent and address technical issues</li>
            </ParagraphList>

            <RouteHeading1>Transfer Of Data</RouteHeading1>
            <ParagraphBody>
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction.
            </ParagraphBody>
            <ParagraphBody>
              If you are located outside USA and choose to provide information
              to us, please note that we transfer the data, including Personal
              Data, to USA and process it there.
            </ParagraphBody>
            <ParagraphBody>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </ParagraphBody>
            <ParagraphBody>
              Nabla will take all steps reasonably necessary to ensure that your
              data is treated securely and in accordance with this Privacy
              Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in
              place including the security of your data and other personal
              information.
            </ParagraphBody>

            <RouteHeading1>Disclosure Of Data</RouteHeading1>
            <RouteHeading2>Legal Requirements</RouteHeading2>
            <ParagraphBody>
              Nabla may disclose your Personal Data in the good faith belief
              that such action is necessary to:
            </ParagraphBody>
            <ParagraphList>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Nabla</li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                To protect the personal safety of users of the Service or the
                public
              </li>
              <li>To protect against legal liability</li>
            </ParagraphList>

            <RouteHeading1>Security Of Data</RouteHeading1>
            <ParagraphBody>
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </ParagraphBody>

            <RouteHeading1>Service Providers</RouteHeading1>
            <ParagraphBody>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), to provide the Service on our
              behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used.
            </ParagraphBody>
            <ParagraphBody>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </ParagraphBody>

            <RouteHeading2>Analytics</RouteHeading2>
            <ParagraphBody>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </ParagraphBody>

            <ParagraphBody>
              <strong>Google Analytics</strong>
            </ParagraphBody>
            <ParagraphBody>
              Google Analytics is a web analysis service provided by Google LLC
              or by Google Ireland Limited, depending on the location this
              Application is accessed from (“Google”). Google utilizes the Data
              collected to track and examine the use of this Application, to
              prepare reports on its activities and share them with other Google
              services.
            </ParagraphBody>
            <ParagraphBody>
              Google may use the Data collected to contextualize and personalize
              the ads of its own advertising network.
            </ParagraphBody>
            <ParagraphBody>
              <strong>Personal Data collected:</strong> Cookies and Usage Data.
            </ParagraphBody>
            <ParagraphBody>
              Place of processing: United States –{' '}
              <Anchor
                href="https://policies.google.com/privacy"
                target="_blank"
              >
                Privacy Policy
              </Anchor>
              ,{' '}
              <Anchor
                target="_blank"
                href="https://tools.google.com/dlpage/gaoptout?hl=en"
              >
                Opt Out
              </Anchor>
              ; Ireland –{' '}
              <Anchor
                target="_blank"
                href="https://policies.google.com/privacy"
              >
                Privacy Policy
              </Anchor>
              . Privacy Shield participant.
            </ParagraphBody>

            <RouteHeading1>Links To Other Sites</RouteHeading1>
            <ParagraphBody>
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </ParagraphBody>
            <ParagraphBody>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </ParagraphBody>

            <RouteHeading1>Children's Privacy</RouteHeading1>
            <ParagraphBody>
              Our Service does not address anyone under the age of 13
              ("Children").
            </ParagraphBody>
            <ParagraphBody>
              We do not knowingly collect personally identifiable information
              from anyone under the age of 13. If you are a parent or guardian
              and you are aware that your Children has provided us with Personal
              Data, please contact us. If we become aware that we have collected
              Personal Data from children without verification of parental
              consent, we take steps to remove that information from our
              servers.
            </ParagraphBody>

            <RouteHeading1>Changes To This Privacy Policy</RouteHeading1>
            <ParagraphBody>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </ParagraphBody>
            <ParagraphBody>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </ParagraphBody>

            <RouteHeading1>Contact Us</RouteHeading1>
            <ParagraphBody>
              If you have any questions about this Privacy Policy, please
              contact us:
            </ParagraphBody>
            <ParagraphList>
              <li>
                By email:{' '}
                <Anchor href="mailto:support@getnabla.app">
                  support@getnabla.app
                </Anchor>
              </li>
            </ParagraphList>
          </RouteSection>
        </Container>
      </Section>
      <Footer style={{ color: styleConstants.textColor.medium }} />
    </>
  );
};

export default MobileAppPrivacy;
