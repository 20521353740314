import React, { useState } from "react";
import styled from "styled-components";
import posed from "react-pose";

const itemAnimationDuration = 3000;
const itemAnimationTimeout = 0.66 * itemAnimationDuration;

const Wrapper = posed.div({
  visible: {
    opacity: 1,
    beforeChildren: true,
    staggerChildren: itemAnimationTimeout
  },
  hidden: { opacity: 0.0 }
});

const Item = posed.div({
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      opacity: {
        type: "keyframes",
        values: [0, 1, 1, 0],
        loop: Infinity,
        duration: itemAnimationDuration,
        times: [0, 0.015, 0.3, 0.33]
      },
      y: {
        type: "keyframes",
        values: [-15, 0, 0, 15],
        loop: Infinity,
        duration: itemAnimationDuration,
        times: [0, 0.1, 0.3, 0.33]
      }
    }
  },
  hidden: { opacity: 0, y: 50 }
});

const ItemBox = styled.div`
  text-align: center;
  margin: auto;
  height: 1.0625em;
`;

const ItemPosition = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

let lastVisible;

export const AnimReplacingBlock = ({ startAnimation, items }) => {
  const [visibleNum, setVisible] = useState(0);
  const amount = items.length;
  if (startAnimation) {
    if (lastVisible !== visibleNum)
      setTimeout(
        () => setVisible(visibleNum < amount - 1 ? visibleNum + 1 : 0),
        itemAnimationTimeout
      );
    lastVisible = visibleNum;
  }
  return (
    <Wrapper pose={startAnimation ? "visible" : "hidden"} initialPose="hidden">
      <ItemBox>
        {items.map((item, idx) => (
          <ItemPosition key={idx}>
            <Item pose={idx === visibleNum ? "visible" : "hidden"}>{item}</Item>
          </ItemPosition>
        ))}
      </ItemBox>
    </Wrapper>
  );
};
