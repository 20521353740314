import styled from 'styled-components';
import { Headline } from './Headline';
import { media } from '../../styles/style-mixins';
import styleConstants from '../../styles/style-constants';

export const HeadlineMeta = styled.p`
  font-size: 16px;
  line-height: 1.19;
  margin: 0;
  font-weight: ${styleConstants.fontWeight.bold};
  color: ${styleConstants.textColor.medium};

  ${media.small`
    line-height: 1.2;
    font-size: 17px;
  `}

  ${media.medium`
    font-size: 18px;
  `}

  & + & {
    margin-top: 0.8em;
  }

  ${Headline} + & {
    margin-top: 0.8em;
  }
`;
