import React from 'react';
import { IconTwitter, IconReddit, IconSteem, IconNabla } from '../Icon';
import { ParagraphBody } from '../typography';
import {
  AmountWrapper,
  Avatar,
  AvatarWrapper,
  IconWrapper,
  MessageWrapper,
  PlatformIcon,
  Wrapper,
} from './styles';

const platformMap = {
  twitter: <IconTwitter />,
  steem: <IconSteem />,
  reddit: <IconReddit />,
  nabla: <IconNabla />,
};

export const TransactionBubble = ({
  amount,
  imagePath,
  message,
  platform,
  recipient,
}) => (
  <Wrapper>
    <AvatarWrapper>
      <Avatar imagePath={imagePath} />
      {platform && (
        <PlatformIcon platform={platform}>
          <IconWrapper>{platformMap[platform]} </IconWrapper>
        </PlatformIcon>
      )}
    </AvatarWrapper>
    <MessageWrapper platform={platform}>
      <AmountWrapper>{amount}</AmountWrapper>
      <ParagraphBody>{message}</ParagraphBody>
    </MessageWrapper>
  </Wrapper>
);
