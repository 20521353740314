import React from 'react';
import {
  AnimFadeInParallax,
  BetaTestButton,
  Container,
  AppStoreDownloadButton,
  GooglePlayDownloadButton,
  Headline,
  HeadlineBox,
  ParagraphBody,
  ParagraphIntro,
  Section,
} from '../../components';
import { Footer } from '../../containers';
import organisationInfo from '../../org-info.json';
import {
  BadgeGrid,
  HeadlineAccent,
  TestButtonWrapper,
} from './landing-common-styles';
import {
  CompensateImage,
  ContentGrid,
  ContentWrapper,
  ImageWrapper,
  ScrollWrapper,
} from './scroll-control-styles';

export const ScrollControlYourMoney = () => (
  <ScrollWrapper>
    <Section>
      <Container>
        <ContentGrid>
          <ContentWrapper>
            <AnimFadeInParallax>
              <HeadlineBox>
                <Headline>
                  Control your money{' '}
                  <HeadlineAccent>without middlemen</HeadlineAccent>{' '}
                  or&nbsp;banks.
                </Headline>
                <ParagraphIntro style={{ color: 'rgba(0,0,0,0.45' }}>
                  A personal safebox in your smartphone, powered by a
                  decentralized blockchain.
                </ParagraphIntro>
              </HeadlineBox>
            </AnimFadeInParallax>
            <CompensateImage>
              <BadgeGrid>
                <AppStoreDownloadButton link={organisationInfo.stores.apple} />
                <GooglePlayDownloadButton
                  link={organisationInfo.stores.google}
                />
              </BadgeGrid>
            </CompensateImage>
          </ContentWrapper>
          <ImageWrapper src="/images/girl-with-phone.png" alt="" />
          <Footer style={{ color: 'rgba(255,255,255,0.8' }} />
        </ContentGrid>
      </Container>
    </Section>
  </ScrollWrapper>
);
