import styled from 'styled-components';
import { media } from '../../styles/style-mixins';

export const Section = styled.section`
  min-height: 60vh;
  display: flex;
  flex-direction: column;

  ${media.small`
    min-height: 100vh;
  `}

  ${props => props.spread && 'flex-grow: 1;'}
`;
