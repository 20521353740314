import React from 'react';
import Plx from 'react-plx';

const parallaxData = [
  {
    start: 0,
    end: 'self',
    properties: [
      {
        startValue: 0,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
  {
    start: 'self',
    duration: 100,
    startOffset: '15vh',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export const AnimFadeInParallax = ({ children }) => {
  return <Plx parallaxData={parallaxData}>{children}</Plx>;
};
