export default {
  baseModule: 10,
  maxWidth: 1320,
  maxWidthNarrow: '40em',
  borderRadius: 10,

  color: {
    primary: '#823CFF',
    yellow: '#fabb08',
    red: 'rgba(160,2,2)',
    green: '#8BC53F',
    blue: '#2e56d2',
  },

  colorBody: '#ffffff',
  colorBorder: '#4C576A',

  paletteBlueGrey: {
    25: '#F4F5FA',
    50: '#ECEFF7',
    75: '#E1E4ED',
    100: '#D5DAE3',
    200: '#C2C5D1',
    300: '#B0B6C4',
    400: '#929caf',
    500: '#6b788e',
    550: '#586074',
    600: '#4C576A',
    650: '#404b60',
    700: '#333A45',
    800: '#1f2532',
    900: '#1A1D23',
  },

  paletteBrand: {
    twitter: '#1da1f2',
    discord: '#738adb',
    steem: '#0f7bff',
    reddit: '#ff4500',
    youtube: '#ff0000',
    nabla: '#823CFF',
  },

  textColor: {
    body: '#000',
    bodyAlt: '#fff',
    medium: '#929caf',
  },

  fontWeight: {
    light: 400,
    normal: 400,
    semibold: 700,
    bold: 700,
  },

  fontFamily: {
    body: 'Lato, sans-serif',
  },

  fontSize: {
    xsmall: 12,
    small: 14,
    body: 17,
    body2: 20,
    body3: 24,
    button: 16,
    heading: 44,
    heading2: 32,
    heading3: 20,
    title: 48,
    display1: 72,
  },

  mq: {
    xxsmall: '420px',
    xsmall: '610px',
    small: '735px',
    medium: '1068px',
  },

  components: {
    section: {
      mq: {
        paddingTop: '92px',
        paddingBottom: '92px',

        small: {
          paddingTop: '138px',
          paddingBottom: '143px',
        },

        medium: {
          paddingTop: '188px',
          paddingBottom: '193px',
        },
      },
    },
  },
};
