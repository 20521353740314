const { useEffect } = require('react');

export const useKey = (
  callback,
  { detectKeys = [], keyEvent = 'keydown' } = {},
  dependencies = []
) => {
  const handleEvent = event => {
    if (detectKeys.includes(event.keyCode)) {
      callback();
    }
  };

  useEffect(() => {
    window.document.addEventListener(keyEvent, handleEvent);
    return () => {
      window.document.removeEventListener(keyEvent, handleEvent);
    };
  }, dependencies);
};
