import React from 'react';
import posed from 'react-pose';

const itemAnimDuration = 200;

const Wrapper = posed.div({
  visible: {
    opacity: 1,
    beforeChildren: true,
    staggerChildren: itemAnimDuration,
  },
  hidden: { opacity: 1 },
});

const Item = posed.div({
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: { opacity: 0, y: 15 },
});

export const AnimFadeInUp = ({ startAnimation, items }) => {
  return (
    <Wrapper pose={startAnimation ? 'visible' : 'hidden'} initialPose="hidden">
      {items.map((item, idx) => (
        <div key={idx} className="AnimFadeInUp-item-box">
          <Item >{item}</Item>
        </div>
      ))}
    </Wrapper>
  );
};
