import styled from 'styled-components';
import styleConstants from '../../styles/style-constants';

export const RouteTitle = styled.h2`
  font-size: 30px;
  line-height: 1.13353;
  font-weight: 600;
  margin: 0;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    font-size: 40px;
    line-height: 1.1;
  }

  @media only screen and (min-width: ${styleConstants.mq.medium}) {
    font-size: 48px;
    line-height: 1.08365;
  }
`;

export const RouteTitleBox = styled.div`
  margin: 29px 0 21px;

  @media only screen and (min-width: ${styleConstants.mq.small}) {
    margin: 48px 0 36px;
  }

  &:first-child {
    margin-top: 0;
  }
`;
